import { ButtonContent } from "../global/boton/ButtonContent";


function HeroSection({ bgImg, bgImg1, bgImg2, bgImg3, title, texts, image }) {
  const BgImages = [
    bgImg,
    bgImg1,
    bgImg2,
    bgImg3,
  ]
  return (
    <>
      <div className="pic-wrapper svgHerosection1  w-full h-screen" >
        {
          BgImages.map((item, index) => {
            return (
              <figure
                key={index}
                className={`pic-${index + 1}`}
                style={{ background: `url("${item}")` }}
              ></figure>
            )
          })
        }
        <div className="z-[4] relative w-4/5 mx-auto h-full grid grid-flow-col-1 justify-center items-center md:pb-[170px] md:pt-[420px] pt-[350px] pb-[100px]">
          <div>
            <div className="w-full flex flex-col justify-center items-center ">
              <h1 className="text-white text-center text-[35px] md:text-[70px]">
                {title}
              </h1>
              <p className="text-white text-center">
                {texts}
              </p>
            </div>
            <div className="w-full flex justify-center py-5">
              <ButtonContent btnStyle={'three'} />
            </div>
          </div>


        </div>
      </div>
    </>
  );
}

export default HeroSection;
