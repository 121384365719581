import { GlobalDataContext } from "../../context/context";
import React, { useContext } from "react";
import imgDefault from '../../assets/image/placeholder.png'
import { VscDebugBreakpointData } from 'react-icons/vsc'
import { ButtonContent } from "../global/boton/ButtonContent";




function Block_12({ image1, image2, image3, title, subtitle2, text, listAbout, listServices, sloganPrincipal }) {

    const { rpdata } = useContext(GlobalDataContext);



    return (

        <section className="w-4/5 mx-auto md:py-2 py-10">
            <div className="md:flex md:flex-row flex flex-col-reverse md:p-0 px-2  relative">
                <div className="md:w-[50%] w-[100%] h-auto flex justify-center items-center ">
                    <div className="md:w-full w-[100%] h-[60%] relative flex justify-end ">
                        <img
                            src={image1 ? image1 : imgDefault}
                            alt="defaultimagen"
                            className="object-cover h-auto md:w-[80%] shadow-2xl rounded-[20px] "
                        />

                    </div>
                </div>
                <div className="md:w-[50%] py-4 md:px-8 px-3 self-center md:text-start text-center" data-aos="fade-up" data-aos-duration="900">

                    <h5 className="separator-about capitalize font-bold textColor1 text-[40px]">
                        {
                            title 
                        }
                    </h5>

                  

                    <p className="pb-4">
                        {
                            text 
                        }
                    </p>


                    <div className="flex md:flex-row flex-col items-center gap-4">
                       
                        <div className="w-1/2 md:my-4 -my-8  flex flex-col justify-start items-center pt-0">
                            {
                                listServices ?
                                    <ul className="grid grid-cols-1 md:grid-cols-1 pt-4">
                                        {rpdata?.dbServices?.slice(0, 4).map((item, index) => {
                                            return (
                                                <li key={index} className="py-1 flex items-center">
                                                    <VscDebugBreakpointData />
                                                    <span>{item.name}</span>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                    : null
                            }
                            {
                                listAbout ?
                                    <ul className='grid grid-cols-1 md:grid-cols-1 pt-3'>
                                        {
                                            rpdata?.dbAbout?.[0].list.length > 1 ?
                                                rpdata?.dbAbout?.[0].list.map((item, index) => {
                                                    return (

                                                        <li key={index} className="py-2 flex items-center">
                                                            <VscDebugBreakpointData />
                                                            <span className="pl-2">{item}</span>
                                                        </li>
                                                    )
                                                })
                                                : null
                                        }
                                    </ul>
                                    : null
                            }
                            <ButtonContent btnStyle='three' />
                        </div>
                    </div>
                    <div className="w-full">
                        <span className="separator-about"></span>
                    </div>
                </div>

            </div>
        </section>
    );
}

export default Block_12;