import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Page/Home";
import About from "./Page/About";
import Services from "./Page/Services";
import Gallery from "./Page/Gallery";
import Contact from "./Page/Contact";
import Loader from "./components/global/Loader";
import "./index.css";
import "./assets/css/styles.css";
import "./assets/css/slider.css";
import "./assets/css/socialmediaicon.css";
import { GlobalDataContext } from "./context/context";
import ErrorPages from "./Page/ErrorPages";
import AOS from "aos";
import "aos/dist/aos.css";

import jsonData from "./dataWeb.json"; // Actualiza la ruta de importación

const App = () => {

  const [rpdata, setrpdata] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    setrpdata(jsonData);

    // Verificar si jsonData.data y jsonData.data.styles existen
    if (jsonData.data && jsonData.data.styles) {
      const styles = jsonData.data.styles;

      // Paletas de colores en la web
      document.documentElement.style.setProperty("--main-color", styles.mainColor);
      document.documentElement.style.setProperty("--secondary-color", styles.secondaryColor);
      document.documentElement.style.setProperty("--three-color", styles.thirdColor);
      document.documentElement.style.setProperty("--heading-color", styles.headingTextColor);
      document.documentElement.style.setProperty("--paragraph-color", styles.paragraphTextColor);
      document.documentElement.style.setProperty("--bg-footer", styles.bgFooter);
      document.documentElement.style.setProperty("--btn", styles.btn);
      document.documentElement.style.setProperty("--btn-hover", styles.btnHover);
      document.documentElement.style.setProperty("--scroll-color", styles.scrollColor);
      document.documentElement.style.setProperty("--icons-menu-movil", styles.iconsMenuMovil);
      document.documentElement.style.setProperty("--overlay-video-content", styles.overlayColor);
    } else {
      console.error("jsonData.data or jsonData.data.styles is undefined");
    }

    // Wait for 1 second before hiding the loader
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);


  return isLoading ? (
    <Loader />
  ) : (
    <div className="App">
      <GlobalDataContext.Provider value={{ rpdata }}>
        <Router>
          {rpdata?.simpleWidgets?.[3]?.activo ? (
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="*" element={<ErrorPages />} />
            </Routes>
          ) : (
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/services" element={<Services />} />
              <Route path="/gallery" element={<Gallery />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="*" element={<ErrorPages />} />
            </Routes>
          )}
        </Router>
      </GlobalDataContext.Provider>
      <div id="recaptcha-container"></div>
    </div>
  );
};

export default App;
