import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import Directories from "../components/Home/Directories";
import FormHome from "../components/Home/FormHome";
import Map from "../components/Contact/MapContent";
import BaseLayout from "../components/global/BaseLayout";
import CounterUp from "../components/global/CounterUp";
import ServicesHome from "../components/Home/ContentServicesHome";
import HeroSection from "../components/Home/HeroSection";
import BlockPrincipal from "../components/block/Block_4";
import BlockSecondary from "../components/block/Block_12";
import BlockAbout from "../components/block/Block_1";
import Modal from "../components/Home/Modal";
import Values from "../components/values/Values_3";
import ThirdBLock from "../components/block/Block_71";


function Home() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Home">
      <div className="md:max-w-full w-full">
        <input className="absolute top-0 -z-50" autoFocus></input>
        <Modal />
        <HeroSection
          title={rpdata?.dbSlogan?.[0]?.slogan}
          texts={rpdata?.dbValues?.[0].description.substring(0, 410)}
          bgImg={rpdata?.gallery?.[5]}
          bgImg1={rpdata?.gallery?.[4]}
          bgImg2={rpdata?.gallery?.[0]}
          bgImg3={rpdata?.gallery?.[8]}
          image={rpdata?.gallery?.[5]}
        />
        <BlockPrincipal
          title={rpdata?.dbSlogan?.[4]?.slogan}
          text={rpdata?.dbHome?.[0].text}
          image1={rpdata?.gallery?.[10]}
          image2={rpdata?.gallery?.[8]}
          image3={rpdata?.gallery?.[5]}
          sloganPrincipal={true}
          listServices={true}
        />
        <Directories />
        <BlockSecondary
          title={rpdata?.dbSlogan?.[0]?.slogan}
          text={rpdata?.dbHome?.[1]?.text}
          image1={rpdata?.gallery?.[0]}
          corte={rpdata?.gallery}
        />
        <CounterUp image={rpdata?.gallery?.[20]} />
        <BlockAbout
          title={rpdata?.dbSlogan?.[1]?.slogan}
          subtitle2={'a little about us'}
          text={rpdata?.dbAbout?.[0]?.text}
          image={rpdata?.gallery?.[12]}
          listsAbout={true}
        />

        <Values
          image1={rpdata?.gallery?.[11]}
          image2={rpdata?.gallery?.[8]}
        />


        <ThirdBLock
          image={rpdata?.gallery?.[13]}
          image3={rpdata?.gallery?.[20]}
          title={rpdata?.dbSlogan?.[2].slogan}
          text={rpdata?.dbAbout?.[1].text}
          listsAbout={false}
        />
        <ServicesHome />
        <div
          className="bgFormHome"
          style={{ backgroundImage: `url("${rpdata?.stock?.[0]}")` }}
        >
          <div className="relative">
            <FormHome />
          </div>
        </div>
        <Map />
      </div>
    </BaseLayout>
  );
}

export default Home;
